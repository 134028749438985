import './App.css';
import Links from './Links';
function App() {
  return (
    <main>
    <div className="App-header">
      <div className='main'>
       <h1>Super<span>Hero</span> </h1>
       <Links/>
      </div>
    </div>
    </main>
  );
}

export default App;